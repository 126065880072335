import React from 'react';
import HomePage from '@v4/src/components/HomePage';
import PropTypes from 'prop-types';
import MemberHome from '@/components/home/Member';
import MetaHead from '@/components/MetaHead';
import { DEPLOY_ENV } from '@/env';
import { fetchStaticArticleListByUrls } from '@separate/api/sep/staticArticles';
import { useTranslation } from '@separate/hooks/translation';
import { useCurrentUser } from '@separate/hooks/user';

export default function Home({ articles }) {
  const { t } = useTranslation();
  const { isLoggedIn} = useCurrentUser();
  const imageUrl = !isLoggedIn && 'https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/bltdc95d9fb74b7fead/62ac4711a064e356abaf4350/new_public_main.png';

  return (
    <>
      <MetaHead
        imageUrl={imageUrl}
        imageType='image/png'
        imageWidth='1500'
        imageHeight='1016'
      />
      {!isLoggedIn && <HomePage articles={articles} />}
      {isLoggedIn && <MemberHome articles={articles} />}
    </>
  );
}

Home.propTypes = {
  articles: PropTypes.array.isRequired,
};

export async function getStaticProps({ locale }) {
  let urls = [
    "/10-maneras-de-reducir-sus-gastos-medicos",
    "/como-acudir-al-dentista-sin-tener-seguro-medico-en-estados-unidos",
    "/aplicaciones-para-ganar-dinero",
    "/donde-puedo-recibir-asesoria-migratoria-gratuita",
    "/que-es-el-itin-y-como-obtenerlo",
    "/10-pasos-para-hacer-un-curriculum-vitae-atractivo",
  ];

  const res = fetchStaticArticleListByUrls(urls, locale);
  const articles = res;

  return {
    props: {
      articles,
    },
  };
}
