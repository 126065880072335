import React from 'react';
import cx from 'classnames';
import findLast from 'lodash/findLast';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ArticleFeed } from '@/components/Articles';
import CTACard from '@/components/cards/CTACard';
import PodercardCard from '@/components/cards/PodercardCard';
import ChatCard from '@/components/home/Member/ChatCard';
import RewardsWrapper from '@/components/RewardsWrapper';
import { useGetCourses } from '@/hooks/courses';
import { useGetSurveyCenterData } from '@/hooks/surveyCenter';
import { RewardsTrophyGray } from '@/icons';
import SuccessModal from '@separate/components/SuccessModal';
import { PODERSALUD_PRODUCT_ID, PODERCARD_PRODUCT_ID } from '@separate/constants/analytics';
import { useTranslation } from '@separate/hooks/translation';
import { useConfirmationRedirect } from '@separate/hooks/useConfirmationRedirect';
import { useCurrentUser } from '@separate/hooks/user';
import { useFetchUserReferrals } from '@separate/hooks/user';
import useWindowSize from '@separate/hooks/useWindowSize';
import { getCookie, setCookie } from '@separate/utilities/cookies';
import { isFeatureEnabled } from '@separate/utilities/features';
import styles from './member.module.scss';
import MembershipDashboard from './MembershipDashboard';

export default function MemberHome({ articles }) {
  const { isLoading: userLoading, user } = useCurrentUser();
  const { isLoading: surveyListIsLoading, data: surveyData } = useGetSurveyCenterData();
  const { isLoading: courseLoading, data: courseData } = useGetCourses();
  const { t } = useTranslation();
  const isOnDesktop = useWindowSize();

  const allowReferrals = user?.is_premium_member && isFeatureEnabled('allow_referrals');
  const hidePodercard = isFeatureEnabled('hide_podercard_references');

  const courses = courseData?.courses || [];

  const [show, setShow] = React.useState(false);
  const [referral, setReferral] = React.useState({});
  useConfirmationRedirect();

  const membershipLevel = () => {
    if (user?.is_premium_member || user?.is_podersalud_user) {
      return 'SEP+';
    }
    return 'SEP';
  };

  const onSuccess = (data) => {
    const findStatusAccepted = findLast(data, (referral) => referral.status === 'accepted');
    const referralCodeViewed = getCookie('referralCodeAccepted');

    setReferral(findStatusAccepted);
    if(!!findStatusAccepted && (findStatusAccepted?.code !== referralCodeViewed)) setShow(true);
  };

  const { fetchUserReferrals } = useFetchUserReferrals({ onSuccess });

  React.useEffect(() => {
    if(!allowReferrals) return;

    fetchUserReferrals({ userId: user?.id });
  }, [allowReferrals, fetchUserReferrals, user?.id]);

  const referralCodeViewed = () => {
    setCookie('referralCodeAccepted', referral?.code);
    setShow(false);
  };

  return (
    <div className={cx(styles.wrapper, 'py-2')}>
      <Container>
        <Row className={cx("mb-3 justify-content-center", styles.userDashboard)}>
          <Col sm={12} className={cx("overflow-hidden", styles.leftCol)}>
            <h1 className={styles.greetings}>{!user?.first_name ? t('home.simple_greeting') : t('home.greeting', { name: user.first_name })}</h1>
            {isOnDesktop && (
              <ChatCard className={styles.chat} membershipLevel={membershipLevel()} />
            )}
          </Col>

          <MembershipDashboard
            user={user}
            membershipLevel={membershipLevel()}
            isLoading={userLoading}
            surveyListIsLoading={surveyListIsLoading}
            totalEarnings={+(surveyData?.earnings.total_earned)}
          />
        </Row>

        {(!userLoading && !user?.is_podercard_user && !hidePodercard) && (
          <Row className="mb-4 justify-content-center">
            <Col sm={12} className="mb-3">
              <PodercardCard
                analyticsProperties={{
                  product_id: PODERCARD_PRODUCT_ID,
                  logged_in: "true",
                }}
              />
            </Col>
          </Row>
        )}

        <Row className={cx(styles.negativeMarginContainer, "mb-4 justify-content-center")}>
          <Col sm={12}>
            <div className={cx("d-flex justify-content-between align-items-center", styles.headerWrapper)}>
              <h2 className={styles.header}>{t("home.index.articles")}</h2>
              <Link href="/articles">
                <a className="link">{t("home.index.view_more")}</a>
              </Link>
            </div>
            <ArticleFeed articles={articles} asGrid={isOnDesktop} />
          </Col>
        </Row>

        <RewardsWrapper
          courseLoading={courseLoading}
          surveyListIsLoading={surveyListIsLoading}
          surveyData={surveyData?.surveys}
          courses={courses.slice(0,2)}
          isOnDesktop={isOnDesktop}
        />

        {(!userLoading && !user?.is_premium_member) && (
          <Row className={cx(styles.negativeMarginContainer, styles.rowWrapper)}>
            <Col md={12}>
              <h2 className={`${styles.sectionHeader} mb-4`}>{t("home.index.unlock_with_premium")}</h2>
            </Col>
            <Col md={4} className="mb-3 d-flex">
              <CTACard
                href="/premium"
                img="https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/bltc4669088ccf88fa0/622814684142c3684bf52e6c/healthcare.png"
                title={t("home.index.discounted_healthcare_title")}
                text={t("home.index.discounted_healthcare_text")}
                buttonText={t("home.index.discounted_healthcare_button_text")}
                analyticsProperties={{
                  product_id: PODERSALUD_PRODUCT_ID,
                  logged_in: "true",
                  cta_id: "medical_discounts",
                }}
              />
            </Col>
            <Col md={4} className="mb-3 d-flex">
              <CTACard
                href="/premium"
                img="https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/blt1f47cbe22a50a9ba/62281468eda9a1043584a57a/content.png"
                title={t("home.index.exclusive_resources_title")}
                text={t("home.index.exclusive_resources_text")}
                buttonText={t("home.index.exclusive_resources_button_text")}
                analyticsProperties={{
                  product_id: PODERSALUD_PRODUCT_ID,
                  logged_in: "true",
                  cta_id: "exclusive_resources",
                }}
              />
            </Col>
            <Col md={4} className="mb-3 d-flex">
              <CTACard
                href="/premium"
                img="https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/bltf29c064dc34b1100/62281468b17dc223f2f2a3aa/chat.png"
                title={t("home.index.support_title")}
                text={t("home.index.support_text")}
                buttonText={t("home.index.support_button_text")}
                analyticsProperties={{
                  product_id: PODERSALUD_PRODUCT_ID,
                  logged_in: "true",
                  cta_id: "real_time_support",
                }}
              />
            </Col>
          </Row>
        )}

        <SuccessModal
          show={show}
          onHide={referralCodeViewed}
          icon={<RewardsTrophyGray />}
          title={t('premium.referral.joined_with_your_referral', { name: referral?.referee?.name })}
          description={t('premium.referral.has_successfully_enrolled', {
            name: referral?.referee?.name,
            discount: referral?.referrer_reward,
          })}
          buttonText={t('premium.referral.great')}
        />
      </Container>
    </div>
  );
}

MemberHome.propTypes = {
  articles: PropTypes.array.isRequired,
};
